<template>
	<v-hover v-model="hover">
		<v-layout mb-2 row justify-center>
			<v-flex text-xs-center>
				<a :href="provider.link">
					<img v-show="!hover" :src="default_logo" contain class="pointer" height="34px" :width="width" max-width="100%" max-height="100%" />
					<img v-show="hover" :src="hover_logo" contain class="pointer" height="34px" :width="width" max-width="100%" max-height="100%" />
				</a>
			</v-flex>
		</v-layout>
	</v-hover>
</template>

<script>
export default {
	name: 'SignInSSOButtonQuickBooks',
	props: {
		provider: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			hover: false,
			logo_name: ''
		}
	},
	computed: {
		default_logo: function () {
			let result = this.logo_name
			if (this.$vuetify.breakpoint.lgAndUp) {
				result += '_lg_default.png'
			} else if (this.$vuetify.breakpoint.mdAndUp) {
				result += '_med_default.png'
			} else {
				result += '_sm_default.png'
			}
			return result
		},
		hover_logo: function () {
			let result = this.logo_name
			if (this.$vuetify.breakpoint.lgAndUp) {
				result += '_lg_hover.png'
			} else if (this.$vuetify.breakpoint.mdAndUp) {
				result += '_med_hover.png'
			} else {
				result += '_sm_hover.png'
			}
			return result
		},
		width: function () {
			let result = 0
			if (this.$vuetify.breakpoint.lgAndUp) {
				result = 154
			} else if (this.$vuetify.breakpoint.mdAndUp) {
				result = 103
			} else if (this.$vuetify.breakpoint.smAndUp) {
				result = 34
			}
			return result
		}
	},
	created: function () {
		if (this.provider && this.provider.signin_logo) {
			this.logo_name = this.provider.signin_logo
		}
	}
}
</script>
